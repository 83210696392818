import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableCell,
} from "@mui/material";

import { DynamicFormItemComponent, DealBuilderApi } from "@unity/components";

export const SupplierApiQueryParamsForm = ({
  product,
  setData,
  option,
  index,
}) => {
  const [providers, setProviders] = useState(null);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const res = await DealBuilderApi.getProvidersByCategory({
        category: product.api_model_data.unity_category,
      });
      if (res?.success) setProviders(res.data);
    };
    if (product?.has_api && product?.api_model_data?.unity_category) getData();
  }, [product]);

  useEffect(() => {
    const getData = async () => {
      const res = await DealBuilderApi.getSupplierQueryParamsModel({
        category: product.api_model_data.unity_category,
        provider: option.api_model_data.provider,
      });
      if (res?.success) setFormData(res.data);
    };
    if (
      product?.api_model_data?.unity_category &&
      option?.api_model_data?.provider
    ) {
      getData();
    }
  }, [product, JSON.stringify(option)]);

  const updateData = (name, value) => {
    setData(index, name, value);
  };

  return (
    <TableCell colSpan={5}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <FormControl
          disabled={!product?.has_api}
          size="small"
          sx={{ width: 200 }}
        >
          <InputLabel id="prov">Providers</InputLabel>
          <Select
            label="Providers"
            value={option?.api_model_data?.provider || ""}
            name="provider"
            onChange={(e) => setData(index, e.target.name, e.target.value)}
            size="small"
          >
            {Array.isArray(providers)
              ? providers.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        {formData
          ? Object.entries(formData).map((el, i) => (
              <DynamicFormItemComponent
                name={el[0]}
                value={option?.api_model_data}
                setData={updateData}
                data={el[1]}
                key={i}
              />
            ))
          : null}
      </Box>
    </TableCell>
  );
};
