import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DynamicLines from "./DynamicLines";
import ModuleFunctions from "./ModuleFunctions";
import ModuleButton from "../common/ModuleButton";

export default function MaterialSingle(props) {
  const [materials, setMaterials] = useState(false);
  const [supplierTrigger, setSupplierTrigger] = useState(0); // This is used to determine if useEffect() needs to be called. The value doesn't matter, just the change!

  const materialsChange = (data) => {
    setMaterials(data);
  };

  const triggerChange = () => {
    setSupplierTrigger(supplierTrigger + 1);
  };

  const handleCancel = () => {
    props.setOpen(false);
    props.setDialog(false);
    props.setErrors(false);
  };

  const handleCheckbox = () => {
    const newData = { ...props.dialogData };

    if (props.dialogData.dynamic) {
      newData["dynamic"] = false;
    } else {
      newData["dynamic"] = true;
      newData["material_id"] = null;
      newData["material_ref"] = null;
      newData["supplier_id"] = null;
    }

    props.componentChange(newData);
  };

  useEffect(() => {
    ModuleFunctions.getCatMaterials({
      category: props.category,
      materialsChange: materialsChange,
    });
  }, [props.category]);

  useEffect(() => {
    ModuleFunctions.getSupplierName({
      id: props.dialogData.supplier_id,
      componentChange: props.componentChange,
    });
  }, [supplierTrigger]);

  if (props.dialogData && materials) {
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="xl"
          open={props.open}
          onClose={handleCancel}
          scroll={"paper"}
          style={{ padding: "15px" }}
        >
          <DialogTitle
            style={{
              background: props.context.theme.sidebar.background,
              color: "white",
            }}
          >
            Product {props.title}
          </DialogTitle>
          <DialogContent>
            <div className="form-row">
              <div className="form-group-sm col-lg-4">
                <FormControl
                  error={(props.errors && props.errors.dynamic) || false}
                  fullWidth
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.dialogData.dynamic || false}
                        onChange={(e) => props.uneditable || handleCheckbox()}
                      />
                    }
                    label="Tick the box to mark this as a dynamic item"
                    size="small"
                  />
                  <FormHelperText>
                    {(props.errors && props.errors.dynamic) || ""}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className="form-row">
              {props.dialogData.dynamic ? (
                <div className="form-group-sm col-lg-12">
                  <TextField
                    label="Item"
                    name="material_name"
                    type="text"
                    value={props.dialogData.material_name || ""}
                    error={
                      (props.errors && props.errors.material_name) || false
                    }
                    helperText={
                      (props.errors && props.errors.material_name) || ""
                    }
                    onChange={(e) =>
                      props.uneditable ||
                      ModuleFunctions.handleComponentChange({
                        name: e.target.name,
                        value: e.target.value,
                        componentChange: props.componentChange,
                      })
                    }
                    fullWidth
                    InputProps={{ readOnly: props.uneditable }}
                    size="small"
                  ></TextField>
                </div>
              ) : (
                <>
                  <div className="form-group-sm col-lg-3">
                    <TextField
                      label="Ref#"
                      name="material_ref"
                      type="text"
                      value={props.dialogData.material_ref || ""}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      size="small"
                    ></TextField>
                  </div>
                  <div className="form-group-sm col-lg-9">
                    <FormControl
                      error={
                        (props.errors && props.errors.material_id) || false
                      }
                      fullWidth
                    >
                      <InputLabel>Item</InputLabel>
                      <Select
                        label="Item"
                        name="material_id"
                        value={props.dialogData.material_id || ""}
                        onChange={(evt, matObj) =>
                          props.uneditable ||
                          ModuleFunctions.handleMaterialChange({
                            event: evt,
                            element: matObj,
                            asset: props.asset,
                            componentChange: props.componentChange,
                            triggerChange: triggerChange,
                          })
                        }
                        inputProps={{ readOnly: props.uneditable }}
                        size="small"
                      >
                        {materials &&
                          materials.map((item, key) => {
                            return (
                              <MenuItem key={key} value={item.id} matObj={item}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {(props.errors && props.errors.material_id) || ""}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </>
              )}
            </div>

            <div className="form-row">
              <div className="form-group-sm col-lg-12">
                {!props.dialogData.dynamic ? (
                  <TextField
                    label="Supplier"
                    name="supplier_name"
                    type="text"
                    value={props.dialogData.supplier_name || ""}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    size="small"
                  ></TextField>
                ) : (
                  <DynamicLines
                    uneditable={props.uneditable}
                    options={props.dialogData.options || []}
                    materials={materials}
                    periods={props.periods}
                    componentChange={props.componentChange}
                    data={props.data}
                  />
                )}
              </div>
            </div>

            {props.asset && (
              <div className="form-row">
                <div className="form-group-sm col-lg-4">
                  <FormControl
                    error={
                      (props.errors && props.errors.writeoff_pc_id) || false
                    }
                    fullWidth
                  >
                    <InputLabel>Write Off Period</InputLabel>
                    <Select
                      label="Write Off Period"
                      name="writeoff_pc_id"
                      value={props.dialogData.writeoff_pc_id || ""}
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentChange({
                          name: e.target.name,
                          value: e.target.value,
                          componentChange: props.componentChange,
                        })
                      }
                      inputProps={{ readOnly: props.uneditable }}
                      size="small"
                    >
                      {props.periods &&
                        props.periods.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>
                      {(props.errors && props.errors.writeoff_pc_id) || ""}
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className="form-group-sm col-lg-4">
                  <TextField
                    label="Write Off Term"
                    name="writeoff_length"
                    type="number"
                    value={props.dialogData.writeoff_length || ""}
                    error={
                      (props.errors && props.errors.writeoff_length) || false
                    }
                    helperText={
                      (props.errors && props.errors.writeoff_length) || ""
                    }
                    onChange={(e) =>
                      props.uneditable ||
                      ModuleFunctions.handleComponentChange({
                        name: e.target.name,
                        value: e.target.value,
                        componentChange: props.componentChange,
                      })
                    }
                    fullWidth
                    inputProps={{ step: "1", min: "0" }}
                    InputProps={{ readOnly: props.uneditable }}
                    size="small"
                  ></TextField>
                </div>

                <div className="form-group-sm col-lg-4">
                  <TextField
                    label="Asset Assignment"
                    name="product_assign_rate"
                    type="number"
                    value={props.dialogData.product_assign_rate || ""}
                    error={
                      (props.errors && props.errors.product_assign_rate) ||
                      false
                    }
                    helperText={
                      (props.errors && props.errors.product_assign_rate) || ""
                    }
                    onChange={(e) =>
                      props.uneditable ||
                      ModuleFunctions.handleComponentChange({
                        name: e.target.name,
                        value: e.target.value,
                        componentChange: props.componentChange,
                      })
                    }
                    fullWidth
                    inputProps={{ min: "0" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      readOnly: props.uneditable,
                    }}
                    size="small"
                  ></TextField>
                </div>
              </div>
            )}

            {props.dialogData.dynamic ? (
              <>
                <div className="form-row">
                  <div className="form-group-sm col-lg-4">
                    <FormControl
                      error={
                        (props.errors && props.errors.periodic_code_id) || false
                      }
                      fullWidth
                    >
                      <InputLabel>Periodic Code</InputLabel>
                      <Select
                        label="Periodic Code"
                        name="periodic_code_id"
                        value={props.dialogData.periodic_code_id || ""}
                        onChange={(e) =>
                          props.uneditable ||
                          ModuleFunctions.handleComponentChange({
                            name: e.target.name,
                            value: e.target.value,
                            componentChange: props.componentChange,
                          })
                        }
                        inputProps={{ readOnly: props.uneditable }}
                        size="small"
                      >
                        {props.periods &&
                          props.periods.map((item, key) => {
                            return (
                              <MenuItem key={key} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {(props.errors && props.errors.periodic_code_id) || ""}
                      </FormHelperText>
                    </FormControl>
                  </div>

                  <div className="form-group-sm col-lg-4">
                    <TextField
                      label="Markup"
                      name="markup"
                      type="number"
                      value={props.dialogData.markup || ""}
                      error={(props.errors && props.errors.markup) || false}
                      helperText={(props.errors && props.errors.markup) || ""}
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentCalcChange({
                          name: e.target.name,
                          value: e.target.value,
                          data: props.dialogData,
                          componentChange: props.componentChange,
                        })
                      }
                      fullWidth
                      inputProps={{ min: "0" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        readOnly: props.uneditable,
                      }}
                      size="small"
                    ></TextField>
                  </div>

                  <div className="form-group-sm col-lg-4">
                    <TextField
                      label="Quantity"
                      name="quantity"
                      type="number"
                      value={props.dialogData.quantity || ""}
                      error={(props.errors && props.errors.quantity) || false}
                      helperText={(props.errors && props.errors.quantity) || ""}
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentCalcChange({
                          name: e.target.name,
                          value: e.target.value,
                          data: props.dialogData,
                          componentChange: props.componentChange,
                        })
                      }
                      fullWidth
                      inputProps={{ min: "0" }}
                      InputProps={{ readOnly: props.uneditable }}
                      size="small"
                    ></TextField>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="form-row">
                  <div className="form-group-sm col-lg-4">
                    <FormControl
                      error={
                        (props.errors && props.errors.periodic_code_id) || false
                      }
                      fullWidth
                    >
                      <InputLabel>Periodic Code</InputLabel>
                      <Select
                        label="Periodic Code"
                        name="periodic_code_id"
                        value={props.dialogData.periodic_code_id || ""}
                        onChange={(e) =>
                          props.uneditable ||
                          ModuleFunctions.handleComponentChange({
                            name: e.target.name,
                            value: e.target.value,
                            componentChange: props.componentChange,
                          })
                        }
                        inputProps={{ readOnly: props.uneditable }}
                        size="small"
                      >
                        {props.periods &&
                          props.periods.map((item, key) => {
                            return (
                              <MenuItem key={key} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {(props.errors && props.errors.periodic_code_id) || ""}
                      </FormHelperText>
                    </FormControl>
                  </div>

                  <div className="form-group-sm col-lg-4">
                    <TextField
                      label="Min. Term"
                      name="minimum_term"
                      type="number"
                      value={props.dialogData.minimum_term || ""}
                      error={
                        (props.errors && props.errors.minimum_term) || false
                      }
                      helperText={
                        (props.errors && props.errors.minimum_term) || ""
                      }
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentChange({
                          name: e.target.name,
                          value: e.target.value,
                          componentChange: props.componentChange,
                        })
                      }
                      fullWidth
                      inputProps={{ step: "1", min: "0" }}
                      InputProps={{ readOnly: props.uneditable }}
                      size="small"
                    ></TextField>
                  </div>

                  <div className="form-group-sm col-lg-4">
                    <TextField
                      label="Quantity"
                      name="quantity"
                      type="number"
                      value={props.dialogData.quantity || ""}
                      error={(props.errors && props.errors.quantity) || false}
                      helperText={(props.errors && props.errors.quantity) || ""}
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentCalcChange({
                          name: e.target.name,
                          value: e.target.value,
                          data: props.dialogData,
                          componentChange: props.componentChange,
                        })
                      }
                      fullWidth
                      inputProps={{ min: "0" }}
                      InputProps={{ readOnly: props.uneditable }}
                      size="small"
                    ></TextField>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group-sm col-lg-3">
                    <TextField
                      label="Cost"
                      name="material_cost"
                      type="number"
                      value={props.dialogData.material_cost || ""}
                      error={(props.errors && props.errors.price) || false}
                      helperText={(props.errors && props.errors.price) || ""}
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentCalcChange({
                          name: e.target.name,
                          value: e.target.value,
                          data: props.dialogData,
                          componentChange: props.componentChange,
                        })
                      }
                      fullWidth
                      inputProps={{ step: "0.01", min: "0" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        readOnly: props.uneditable,
                      }}
                      size="small"
                    ></TextField>
                  </div>

                  <div className="form-group-sm col-lg-3">
                    <TextField
                      label="Markup"
                      name="markup"
                      type="number"
                      value={props.dialogData.markup || ""}
                      error={(props.errors && props.errors.markup) || false}
                      helperText={(props.errors && props.errors.markup) || ""}
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentCalcChange({
                          name: e.target.name,
                          value: e.target.value,
                          data: props.dialogData,
                          componentChange: props.componentChange,
                        })
                      }
                      fullWidth
                      inputProps={{ min: "0" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        readOnly: props.uneditable,
                      }}
                      size="small"
                    ></TextField>
                  </div>

                  <div className="form-group-sm col-lg-3">
                    <TextField
                      label="Unit Price"
                      name="unit_price"
                      type="number"
                      value={props.dialogData.unit_price || ""}
                      error={(props.errors && props.errors.unit_price) || false}
                      helperText={
                        (props.errors && props.errors.unit_price) || ""
                      }
                      fullWidth
                      inputProps={{ step: "0.01", min: "0" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      size="small"
                    ></TextField>
                  </div>

                  <div className="form-group-sm col-lg-3">
                    <TextField
                      label="Total Price"
                      name="price"
                      type="number"
                      value={props.dialogData.price || ""}
                      error={(props.errors && props.errors.price) || false}
                      helperText={(props.errors && props.errors.price) || ""}
                      onChange={(e) =>
                        props.uneditable ||
                        ModuleFunctions.handleComponentCalcChange({
                          name: e.target.name,
                          value: e.target.value,
                          data: props.dialogData,
                          componentChange: props.componentChange,
                        })
                      }
                      fullWidth
                      inputProps={{ step: "0.01", min: "0" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                        readOnly: props.uneditable,
                      }}
                      size="small"
                    ></TextField>
                  </div>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {!props.uneditable && (
              <ModuleButton
                btnIcon="check"
                text="submit"
                style={{
                  background: "green",
                  color: "white",
                  marginRight: 10,
                }}
                tip="Submit"
                onClick={() => {
                  props.handleSubmit();
                }}
              />
            )}
            <ModuleButton
              btnIcon="close"
              text="cancel"
              style={{
                background: "red",
                color: "white",
                marginRight: 10,
              }}
              tip="Cancel"
              onClick={handleCancel}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <></>;
  }
}
