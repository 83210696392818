import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import ModuleFunctions from "./ModuleFunctions";
import OutputLines from "../modules/OutputLines";
import { DynamicFormItemComponent, DealBuilderApi } from "@unity/components";

export default function ProductOverview(props) {
  const [prodCats, setProdCats] = useState(false);
  const [groups, setGroups] = useState(false);
  const [lines, setLines] = useState(false);
  const [unityCategory, setUnityCategory] = useState(null);
  const [catApiFormData, setCatApiFormData] = useState([]);
  const [apiData, setApiData] = useState(null);

  const uneditable =
    props.type === "view" || !props[props.type] || props.data.retired;

  const prodCatsChange = (data) => {
    setProdCats(data);
  };

  const groupsChange = (data) => {
    setGroups(data);
  };

  const linesChange = (data) => {
    setLines(data);
  };

  const formChange = (name, value) => {
    ModuleFunctions.handleProductChange({
      name,
      value,
      productChange: props.productChange,
    });
  };

  const removeApiData = () => {
    setApiData(null);
    formChange("api_model_data", null);
  };

  const handleApiData = (name, value) => {
    const localData =
      props.data?.api_model_data &&
      typeof props.data.api_model_data === "object"
        ? { ...props.data.api_model_data }
        : {};
    localData[name] = value;
    formChange("api_model_data", localData);
  };

  useEffect(() => {
    if (props.data.sbu_id) {
      ModuleFunctions.setCurrentProductStructure({
        data: props.data,
        structure: props.structure,
        prodCatsChange: prodCatsChange,
        groupsChange: groupsChange,
        linesChange: linesChange,
      });
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await DealBuilderApi.getCategoryTypes();
      if (res?.success) setUnityCategory(res.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await DealBuilderApi.getCategoryQueryParamsModel({
        category: props.data.api_model_data.unity_category,
      });
      if (res?.success) setCatApiFormData(res.data);
    };
    if (props.data?.api_model_data?.unity_category) getData();
  }, [props.data]);

  useEffect(() => {
    if (apiData) formChange("api_model_data", apiData);
  }, [apiData]);

  return (
    <div className="block">
      <div className="container-fluid">
        <div style={{ width: "100%" }}>
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h4>Product Overview</h4>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-7">
              <TextField
                label="Public Name"
                name="name"
                type="text"
                value={props.data.name || ""}
                error={(props.errors && props.errors.name) || false}
                helperText={(props.errors && props.errors.name) || ""}
                onChange={(e) =>
                  uneditable || formChange(e.target.name, e.target.value)
                }
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>

            <div className="form-group-sm col-lg-2">
              <TextField
                label="Ref#"
                name="ref"
                type="text"
                value={props.data.ref || ""}
                size="small"
                fullWidth
                disabled
              ></TextField>
            </div>

            <div className="form-group-sm col-lg-1">
              <TextField
                label="Version"
                name="version"
                type="text"
                value={props.data.version || ""}
                size="small"
                fullWidth
                disabled
              ></TextField>
            </div>

            <div className="form-group-sm col-lg-2">
              <TextField
                label="Stage"
                name="life_cycle_stage"
                type="text"
                value={props.data.life_cycle_stage || ""}
                size="small"
                fullWidth
                disabled
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-4">
              <FormControl
                error={(props.errors && props.errors.owner_id) || false}
                fullWidth
                size="small"
              >
                <InputLabel>Product Owner</InputLabel>
                <Select
                  label="Product Owner"
                  name="owner_id"
                  value={props.data.owner_id || ""}
                  onChange={(evt, personObj) =>
                    uneditable ||
                    ModuleFunctions.handleOwnerChange({
                      event: evt,
                      element: personObj,
                      productChange: props.productChange,
                    })
                  }
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.personnel &&
                    props.personnel.map((item, key) => {
                      return (
                        <MenuItem
                          key={key}
                          value={item.contact_id}
                          personObj={item}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {(props.errors && props.errors.owner_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="form-group-sm col-lg-4">
              <FormControl
                error={(props.errors && props.errors.sbu_id) || false}
                fullWidth
                size="small"
              >
                <InputLabel>Strategic Business Unit</InputLabel>
                <Select
                  label="Strategic Business Unit"
                  name="sbu_id"
                  value={props.data.sbu_id || ""}
                  onChange={(evt, structObj) =>
                    uneditable ||
                    ModuleFunctions.handleSbuChange({
                      current: props.data.sbu_id,
                      selected: evt.target.value,
                      element: structObj,
                      productChange: props.productChange,
                      prodCatsChange: prodCatsChange,
                    })
                  }
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.structure &&
                    props.structure.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id} structObj={item}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {(props.errors && props.errors.sbu_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="form-group-sm col-lg-4">
              <FormControl
                error={(props.errors && props.errors.prod_cat_id) || false}
                fullWidth
                size="small"
              >
                <InputLabel>Product Category</InputLabel>
                {prodCats ? (
                  <Select
                    label="Product Category"
                    name="prod_cat_id"
                    value={props.data.prod_cat_id || ""}
                    onChange={(evt, structObj) =>
                      uneditable ||
                      ModuleFunctions.handleProdCatChange({
                        current: props.data.prod_cat_id,
                        selected: evt.target.value,
                        element: structObj,
                        productChange: props.productChange,
                        groupsChange: groupsChange,
                      })
                    }
                    inputProps={{ readOnly: uneditable }}
                    size="small"
                  >
                    {prodCats &&
                      prodCats.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.id} structObj={item}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                ) : (
                  <Select
                    value={0}
                    name="prod_cat_id"
                    disabled
                    fullWidth
                    size="small"
                  >
                    <MenuItem value={0}>
                      Please choose a Strategic Business Unit
                    </MenuItem>
                  </Select>
                )}
                <FormHelperText>
                  {(props.errors && props.errors.prod_cat_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-4">
              <FormControl
                error={(props.errors && props.errors.group_id) || false}
                fullWidth
                size="small"
              >
                <InputLabel>Product Group</InputLabel>
                {groups ? (
                  <Select
                    label="Product Group"
                    name="group_id"
                    value={props.data.group_id || ""}
                    onChange={(evt, structObj) =>
                      uneditable ||
                      ModuleFunctions.handleProdGroupChange({
                        current: props.data.group_id,
                        selected: evt.target.value,
                        element: structObj,
                        productChange: props.productChange,
                        linesChange: linesChange,
                      })
                    }
                    inputProps={{ readOnly: uneditable }}
                    size="small"
                  >
                    {groups &&
                      groups.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.id} structObj={item}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                ) : (
                  <Select
                    value={0}
                    name="group_id"
                    disabled
                    fullWidth
                    size="small"
                  >
                    <MenuItem value={0}>
                      Please choose a Product Category
                    </MenuItem>
                  </Select>
                )}
                <FormHelperText>
                  {(props.errors && props.errors.group_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="form-group-sm col-lg-4">
              <FormControl
                error={(props.errors && props.errors.line_id) || false}
                fullWidth
                size="small"
              >
                <InputLabel>Product Line</InputLabel>
                {lines ? (
                  <Select
                    label="Product Line"
                    name="line_id"
                    value={props.data.line_id || ""}
                    onChange={(e) =>
                      uneditable || formChange(e.target.name, e.target.value)
                    }
                    inputProps={{ readOnly: uneditable }}
                    size="small"
                  >
                    {lines &&
                      lines.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                ) : (
                  <Select
                    value={0}
                    name="line_id"
                    disabled
                    fullWidth
                    size="small"
                  >
                    <MenuItem value={0}>Please choose a Product Group</MenuItem>
                  </Select>
                )}
                <FormHelperText>
                  {(props.errors && props.errors.line_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="form-group-sm col-lg-4">
              <FormControl
                error={(props.errors && props.errors.channel_id) || false}
                fullWidth
                size="small"
              >
                <InputLabel>Sales Channel</InputLabel>
                <Select
                  label="Sales Channel"
                  name="channel_id"
                  value={props.data.channel_id || ""}
                  onChange={(e) =>
                    uneditable || formChange(e.target.name, e.target.value)
                  }
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.channels &&
                    props.channels.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {(props.errors && props.errors.channel_id) || ""}
                </FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className="form-row">
            <Grid container spacing={1}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="has_api"
                      checked={props.data?.has_api}
                      onChange={(e) => {
                        if (!e.target.checked) removeApiData();
                        formChange(e.target.name, e.target.checked);
                      }}
                      disabled={!props.data.line_id}
                    />
                  }
                  label="Has API?"
                />
              </Grid>
              {Array.isArray(unityCategory) ? (
                <Grid item>
                  <FormControl size="small" sx={{ width: 200 }}>
                    <InputLabel id="unitycat">Unity Categories</InputLabel>
                    <Select
                      label="Unity Categories"
                      name="unity_category"
                      value={props.data?.api_model_data?.unity_category || ""}
                      onChange={(e) =>
                        handleApiData(e.target.name, e.target.value)
                      }
                      disabled={!props.data?.has_api}
                      size="small"
                    >
                      {unityCategory.map((el, i) => (
                        <MenuItem key={i} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              {
                //if category not null, map returned params via dynamic form,
                props.data?.has_api &&
                catApiFormData &&
                typeof catApiFormData === "object" &&
                Array.isArray(Object.entries(catApiFormData))
                  ? Object.entries(catApiFormData).map((el, i) => (
                      <Grid item key={i} xs={el[1]?.fullWidth ? 12 : "auto"}>
                        <DynamicFormItemComponent
                          name={el[0]}
                          data={el[1]}
                          value={props.data.api_model_data}
                          setData={handleApiData}
                        />
                      </Grid>
                    ))
                  : null
              }
            </Grid>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-12">
              <TextField
                label="Public Description"
                name="description"
                type="text"
                value={props.data.description || ""}
                multiline
                minRows={3}
                maxRows={10}
                error={(props.description && props.errors.description) || false}
                helperText={
                  (props.description && props.errors.description) || ""
                }
                onChange={(e) =>
                  uneditable || formChange(e.target.name, e.target.value)
                }
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-12">
              <TextField
                label="Internal Notes"
                name="notes"
                type="text"
                value={props.data.notes || ""}
                multiline
                minRows={3}
                maxRows={10}
                error={(props.errors && props.errors.notes) || false}
                helperText={(props.errors && props.errors.notes) || ""}
                onChange={(e) =>
                  uneditable || formChange(e.target.name, e.target.value)
                }
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-12">
              <OutputLines
                uneditable={uneditable}
                output_lines={props.data.output_lines || []}
                periods={props.periods}
                charges={props.charges}
                methods={props.methods}
                vats={props.vats}
                componentChange={props.componentChange}
                productChange={props.productChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
