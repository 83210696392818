import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ProductsApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin } from "../services/Permissions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleButton from "../common/ModuleButton";
import ProductOverview from "../modules/ProductOverview";
import BillOfProduct from "../modules/BillOfProduct";
import ProductMaterials from "../modules/ProductMaterials";
import ProductLabour from "../modules/ProductLabour";
import ProductActions from "../modules/ProductActions";
import ModuleFunctions from "../modules/ModuleFunctions";
import RelatedResources from "../modules/RelatedResources";
import ScheduleCapture from "../modules/ScheduleCapture";
import ProductDocuments from "../modules/ProductDocuments";
import UploadedDocumentEdit from "../modules/UploadedDocumentEdit";
import MaterialSingle from "../modules/MaterialSingle";
import LabourSingle from "../modules/LabourSingle";

const defaultTab = {
  icon: "dvr",
  label: "overview",
  component: "ProductOverview",
};

export default function ProductEdit(props) {
  const params = useParams();
  const location = useLocation();
  // console.log("View EDIT: ",location);

  const [tabs, setTabs] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedTab, setSelectedTab] = useState({ ...defaultTab });
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [calcTrigger, setCalcTrigger] = useState(false); // This is used to trigger a recalculation of the bop & output lines then retreive the new product!
  const [prodTrigger, setProdTrigger] = useState(false); // This is used to simply update the product object only!
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(location.state.hasErrors || false);
  const [personnel, setPersonnel] = useState(false);
  const [channels, setChannels] = useState(false);
  const [structure, setStructure] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);
  const [methods, setMethods] = useState(false);
  const [vats, setVats] = useState(false);
  const [tags, setTags] = useState(false);
  const [schedules, setSchedules] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [visibilities, setVisibilities] = useState(false);
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState(false);
  const [docEdit, setDocEdit] = useState(false);
  const [matEdit, setMatEdit] = useState(false);
  const [labEdit, setLabEdit] = useState(false);
  const [actEdit, setActEdit] = useState(false);
  const [dialogData, setDialogData] = useState(false);

  const create = CanCreate("products");
  const read = CanRead("products");
  const edit = CanUpdate("products");
  const admin = CanModuleAdmin("products");
  const readAll = CanReadAll("products");
  
  const navigate = useNavigate();

  // TODO: at some point will need to add the functionality to handle situations where old products have categories that have been deleted!
  const tabsChange = (tabData) => {
    const newData = [
      {
        icon: "dvr",
        label: "overview",
        component: "ProductOverview",
      },
      {
        icon: "summarize",
        label: "BOP",
        component: "BillOfProduct",
      },
    ];

    Array.isArray(tabData)
      ? tabData.map((cat) => {
          newData.push({
            icon: cat.icon_name,
            label: cat.name,
            category: cat.id, // for getting the related materials on the page
            component: cat.capital_asset ? "ProductAssets" : "ProductMaterials", // to determine the rendered component
          });
        })
      : null;

    newData.push(
      {
        icon: "directions_walk",
        label: "labour",
        component: "ProductLabour",
      },
      {
        icon: "event",
        label: "actions",
        component: "ProductActions",
      }
    );

    setTabs(newData);
  };

  const dialogChange = (data) => {
    setOpen(true);
    setDialogData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const mediaChange = (data) => {
    setMedia(data);
  };

  const documentChange = (data) => {
    setDocEdit(true);
    setDialogData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const componentChange = (data) => {
    setMatEdit(true);
    setDialogData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const labourChange = (data) => {
    setLabEdit(true);
    setDialogData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const productChange = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const structChange = (data) => {
    setStructure(data);
  };

  const scheduleChange = (data) => {
    setSchedules(data);
  };

  const docsChange = (data) => {
    setDocuments(data);
  };

  const channelChange = (data) => {
    setChannels(data);
  };

  const periodsChange = (data) => {
    setPeriods(data);
  };

  const vatsChange = (data) => {
    setVats(data);
  };

  const chargesChange = (data) => {
    setCharges(data);
  };

  const methodsChange = (data) => {
    setMethods(data);
  };

  const tagsChange = (data) => {
    setTags(data);
  };

  const visiChange = (data) => {
    setVisibilities(data);
  };

  const getSupportingData = async () => {
    ModuleFunctions.getMaterialCats({ tabsChange: tabsChange });
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getChannels({ channelChange: channelChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
    ModuleFunctions.getPaymentMethods({ methodsChange: methodsChange });
    ModuleFunctions.getVatRates({ vatsChange: vatsChange });
    ModuleFunctions.getTags({ tagsChange: tagsChange });
    ModuleFunctions.getVisibilities({ visiChange: visiChange });
  };

  const handleAddItem = () => {
    if (selectedTab.component === "ProductLabour") {
      setLabEdit(true);
    } else if (selectedTab.component === "ProductActions") {
      setActEdit(true);
    } else {
      setMatEdit(true);
    }

    setDialogData({
      asset: selectedTab.component === "ProductAssets",
      dynamic: false,
      product_id: data.id,
      material_cat_id: selectedTab.category,
      material_cat_name: selectedTab.label,
    });
  };

  const handleSave = async () => {
    setLoading({
      status: true,
      data: "Updating your Product, Please Wait....",
    });

    const res = await ProductsApi.updateProduct(
      params.id,
      data
    );

    if (res.success) {
      setProdTrigger(true);
      productChange({ changed: false });
      setLoading({ status: false });
    } else {
        if(res.errors) {
            setErrors(res.errors);
            setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
            setTimeout(() => {
                setLoading({ status: false });
            }, 3000);
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                navigate("/products/index");
                setLoading({ status: false });
            }, 3000);
        }
    }
  };

  const handleCaptureSubmit = async () => {
      setLoading({ status: true, data: "Saving your reference, Please Wait...." });
  
      const res = await ProductsApi.saveSchedule(dialogData);
  
      if(res.success) {
          setTimeout(() => {
              triggerChange();
              setOpen(false);
              setDialogData(false);
              setErrors(false);
              setLoading({ status: false });
          });
      } else {
          setLoading({ status: true, data: res.message });
          setTimeout(() => {
              navigate('/products/index');
              setLoading({ status: false });
          }, 3000);
      }
  };

  const handleDocumentSubmit = async () => {
    setLoading({
      status: true,
      data: "Saving your document changes, Please Wait....",
    });

    const res = await ProductsApi.updateDocument(dialogData.id, dialogData);

    if (res.success) {
      setTimeout(() => {
        triggerChange();
        setDocEdit(false);
        setDialogData(false);
        setErrors(false);
        setLoading({ status: false });
      });
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
          navigate('/products/index');
          setLoading({ status: false });
      }, 3000);
    }
  };

  // Works for both components & labour_costs
  const handleComponentSave = async () => {
    setLoading({
      status: true,
      data: "Updating your Product, Please Wait....",
    });

    let res = null;
    let funcName = "Labour";
    let arrayName = "labour_costs";

    if (selectedTab.component !== "ProductLabour") {
      funcName = "Component";
      arrayName = "components";
    }

    if (dialogData.id) {
      res = await ProductsApi[`update${funcName}`](dialogData.id, dialogData);
    } else {
      res = await ProductsApi[`save${funcName}`](dialogData);
    }

    if (res.success) {
      setProdTrigger(true); // Added back here as was in bopCalculate to trigger re-render with new details!
      setMatEdit(false);
      setLabEdit(false);
      setDialogData(false);
      setErrors(false);
      setLoading({ status: false });
    } else {
        if(res.errors) {
            setErrors(res.errors);
            setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
            setTimeout(() => {
                setLoading({ status: false });
            }, 3000);
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                navigate("/prodsucts/index");
                setLoading({ status: false });
            }, 3000);
        }
    }
  };

  useEffect(() => {
    if(prodTrigger) {
      ModuleFunctions.getProduct({ id: params.id, productChange: productChange });
      setProdTrigger(false);
    } else {
      getSupportingData();
      ModuleFunctions.getProduct({ id: params.id, productChange: productChange });
      ModuleFunctions.getStructure({ structChange: structChange });
      ModuleFunctions.getSchedules({ scheduleChange: scheduleChange }); // this is the list of available schedules for linking
      ModuleFunctions.getDocuments({ docsChange: docsChange }); // this is the list of available documents for linking
    }
  }, [fetchTrigger, calcTrigger, prodTrigger]);

  if (tabs && edit && data && structure) {
    return (
      <>
        <AppHeaderComponent
          addNew={() =>
            tabValue < 2 ? null : (
              <ModuleButton
                text="Add Item"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: props.context.theme.top_menu.button_background,
                  color: props.context.theme.top_menu.button_font,
                }}
                onClick={handleAddItem}
              />
            )
          }
          saveBtn={data.changed ? handleSave : null}
          context={props.context}
          theme={props.context.theme}
          name="products"
          subpage="edit"
          spacer={true}
        />

        <ModuleTabs
          tabs={tabs}
          value={tabValue}
          setValue={setTabValue}
          setCurrentTab={setSelectedTab}
        />
        {selectedTab.component === "ProductOverview" && (
          <div style={{ paddingTop: 66 }}>
            <ProductOverview
              context={props.context}
              type="edit"
              create={create}
              read={read}
              edit={edit}
              data={data}
              errors={errors}
              personnel={personnel}
              channels={channels}
              structure={structure}
              periods={periods}
              charges={charges}
              methods={methods}
              vats={vats}
              productChange={productChange}
            />

            <RelatedResources
              admin={admin}
              uneditable={!edit || data.retired}
              context={props.context}
              object_id={params.id}
              object_type="product"
              references={data.schedules}
              linkedDocs={data.documents}
              tags={tags}
              dialogChange={dialogChange}
              mediaChange={mediaChange}
              triggerChange={triggerChange}
            />

            <ProductDocuments
              admin={admin}
              uneditable={!edit || data.retired}
              context={props.context}
              object_id={params.id}
              object_type="product"
              linkedDocs={data.documents}
              open={media}
              data={documents}
              documentChange={documentChange}
              onClose={mediaChange}
              triggerChange={triggerChange}
            />
          </div>
        )}
        {selectedTab.component === "BillOfProduct" && (
          <div style={{ paddingTop: 66 }}>
            <BillOfProduct
              uneditable={!edit || data.retired}
              data={data}
              periods={periods}
              productChange={productChange}
            />
          </div>
        )}
        {(selectedTab.component === "ProductAssets" ||
          selectedTab.component === "ProductMaterials") && (
          <div style={{ paddingTop: 86 }}>
            <ProductMaterials
              context={props.context}
              type="edit"
              asset={selectedTab.component === "ProductAssets"}
              category={selectedTab.category}
              uneditable={!edit || data.retired}
              read={read}
              data={data}
              periods={periods}
              setMatEdit={setMatEdit}
              setDialogData={setDialogData}
              triggerChange={triggerChange}
              setCalcTrigger={setCalcTrigger}
            />
          </div>
        )}
        {selectedTab.component === "ProductLabour" && (
          <div style={{ paddingTop: 86 }}>
            <ProductLabour
              context={props.context}
              type="edit"
              uneditable={!edit || data.retired}
              read={read}
              data={data}
              periods={periods}
              setLabEdit={setLabEdit}
              setDialogData={setDialogData}
              triggerChange={triggerChange}
              setCalcTrigger={setCalcTrigger}
            />
          </div>
        )}
        {selectedTab.component === "ProductActions" && (
          <div style={{ paddingTop: 86 }}>
            <ProductActions
              context={props.context}
              type="edit"
              create={create}
              read={read}
              edit={edit}
              data={data}
              errors={errors}
              personnel={personnel}
              channels={channels}
              structure={structure}
              productChange={productChange}
            />
          </div>
        )}

        <ScheduleCapture
          title="Document Reference Selection"
          info="You can select a published document, and optionally a specific section of that document to reference."
          open={open}
          setOpen={setOpen}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          schedules={schedules}
          dialogChange={dialogChange}
          handleSubmit={handleCaptureSubmit}
        />

        <UploadedDocumentEdit
          title="Uploaded Document Edit"
          info={`Edit attributes for document: ${dialogData.name}`}
          open={docEdit}
          setOpen={setDocEdit}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          tags={tags}
          visibilities={visibilities}
          documentChange={documentChange}
          handleSubmit={handleDocumentSubmit}
        />

        <MaterialSingle
          context={props.context}
          asset={selectedTab.component === "ProductAssets"}
          open={matEdit}
          setOpen={setMatEdit}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          data={data}
          periods={periods}
          vats={vats}
          category={selectedTab.category}
          title={selectedTab.label}
          uneditable={!edit || data.retired}
          componentChange={componentChange}
          handleSubmit={handleComponentSave}
        />

        <LabourSingle
          context={props.context}
          open={labEdit}
          setOpen={setLabEdit}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          data={data}
          periods={periods}
          vats={vats}
          category={selectedTab.category}
          title={selectedTab.label}
          uneditable={!edit || data.retired}
          labourChange={labourChange}
          handleSubmit={handleComponentSave}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
