import React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

const MybeTabs = styled((props) => <Tabs {...props} />)({
  borderBottom: "1px solid #3f51b5",
  "& .MuiTabs-indicator": {
    backgroundColor: "red",
  },
});

const MybeTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "uppercase",
    minWidth: 150,
    fontWeight: theme.typography.fontWeightRegular,
    color: "#d9d9d9",
    "&:hover": {
      color: "#fff",
      opacity: 1,
    },
    "&.Mui-selected": {
      outline: "none",
      color: "#fff",
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

/*
tabs = []<{id,label,icon}>
*/

export default function ModuleTabs({ value, tabs, setValue, setCurrentTab }) {
  const handleChange = (e, newValue) => {
    setValue(newValue);
    if (setCurrentTab) setCurrentTab(tabs[newValue]);
  };

  return (
    <div
      style={{
        zIndex: 999,
        position: "fixed",
        top: 160,
        flexGrow: 1,
        width: "100%",
        backgroundColor: "#fff",
      }}
    >
      <AppBar position="static">
        <MybeTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
        >
          {tabs.map((item, key) => {
            return (
              <MybeTab
                label={item.label ? item.label : null}
                icon={<Icon>{item.icon}</Icon>}
                key={key}
              />
            );
          })}
        </MybeTabs>
      </AppBar>
    </div>
  );
}
