import React, { useEffect, useState } from "react";
import { ProductsApi, StorageManager } from "@unity/components";
import { Button, Tooltip, Backdrop, CircularProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import DataTable from "../common/DataTable";

const DEFAULTPERPAGE = 10;
const sm = new StorageManager();
const cachedItem = "product-archived-page-initial-data";

export default function ArchivedIndex({ context, bus }) {
  const initData = sm.getObject(cachedItem); // {page,pageSize,search,sort,filter}
  const localPage = initData?.page || null;
  const localPageSize = initData?.pageSize || null;
  const localSearch = initData?.search || null;
  const localSort = initData?.sort || null;

  const [searchedText, setSearchedText] = useState(localSearch || "");
  const [page, setPage] = useState(localPage || 0);
  const [pageSize, setPageSize] = useState(localPageSize || DEFAULTPERPAGE);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productTotal, setProductTotal] = useState(0);
  const [sortModel, setSortModel] = useState(
    localSort
      ? [...localSort]
      : [
          {
            field: "created_at",
            sort: "desc",
          },
        ]
  );
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const navigate = useNavigate();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    navigate(`/products/${path}/${params.id}`, {
      state: { ...params }
    });
  };

  const handleDuplicate = async (event, id) => {
    setLoading(true);

    const res = await ProductsApi.productDuplicate(id, {});

    if (res.success) {
      setLoading(false);

      handleLinkClick(event, { id: res.data.id, hasErrors: false }, "edit");
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
    loadData();
  };

  const columns = [
    {
      field: "product_ref",
      valueGetter: (params) => params.row.ref,
      headerName: "Ref#",
      flex: 0.5,
    },
    {
      field: "version_number",
      valueGetter: (params) => params.row.version,
      headerName: "ver",
      flex: 0.2,
    },
    {
      field: "product_name",
      valueGetter: (params) => params.row.name,
      headerName: "Product Name",
      flex: 1,
    },
    {
      field: "sbu_id",
      headerName: "Business Unit",
      flex: 0.8,
      valueGetter: (cellValues) => {
        if (cellValues.row.sbu_id) {
          const pc = bus.find((e) => cellValues.row.sbu_id === e.id);
          return pc.name;
        } else {
          return null;
        }
      },
    },
    { field: "owner_name", headerName: "Product Owner", flex: 0.5 },
    { field: "created_at", headerName: "Created" },
    {
      field: "",
      headerName: "Action",
      flex: 0.6,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            <Tooltip title={"View"}>
              <Button
                style={{
                  color: context.theme.sidebar.background,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                onClick={(event) => {
                  handleLinkClick(event, cellValues.row, "view");
                }}
                variant="contained"
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>

            {context.auth.access.products.C && (
              <Tooltip title={"Duplicate"}>
                <Button
                  style={{
                    color: context.theme.sidebar.background,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                  onClick={(event) => {
                    handleDuplicate(event, cellValues.row.id);
                  }}
                  variant="contained"
                >
                  <ContentCopyIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const handlePageSizeChange = async (newSize) => {
    sm.setItem(cachedItem, { ...initData, pageSize: newSize });
    setPageSize(newSize);
    setPage(0);
    setFetchTrigger((prev) => prev + 1);
  };

  const handlePageChange = async (newPage) => {
    sm.setItem(cachedItem, { ...initData, newPage });
    setPage(newPage);
    setFetchTrigger((prev) => prev + 1);
  };

  const handleSort = (sort) => {
    setSortModel(sort);
    sm.setObject(cachedItem, { ...initData, sort });
    setFetchTrigger((prev) => prev + 1);
  };

  const handleSearchTextChange = (value) => {
    setSearchedText(value);
    sm.setObject(cachedItem, { ...initData, search: value });
    setFetchTrigger((prev) => prev + 1);
  };

  const loadData = async () => {
    setLoading(true);
    const sbus =
      bus && searchedText
        ? bus
            .filter((el) => {
              const name = el.name.toUpperCase();
              return name.indexOf(searchedText.toUpperCase()) !== -1;
            })
            .map((el) => el.id)
        : [];
    const res = await ProductsApi.getArchivedProducts({
      read_all: context.auth.access.products.RA,
      pagination: true,
      page: page + 1,
      per_page: pageSize,
      search_term: searchedText,
      sort_name: sortModel[0]?.field,
      sort_direction: sortModel[0]?.sort,
      search_list: [{ column: "sbu_id", data: sbus }],
    });
    if (!res.success) return alert("Unable to load data");
    setProducts(res.data);
    setProductTotal(res.meta.total);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [fetchTrigger, bus]);

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handlePageSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: productTotal,
    autoHeight: true,
    height: 680,
  };

  return products ? (
    <>
      <DataTable
        columns={columns}
        data={products}
        pagination={true}
        options={options}
        allowDelete={false}
        searchText={searchedText}
        setSearchText={handleSearchTextChange}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        sortModel={sortModel}
        setSortModel={handleSort}
      />
      <Backdrop open={loading} onClose={() => setLoading(false)}>
        <CircularProgress />
      </Backdrop>
    </>
  ) : (
    <h1>No records found</h1>
  );
}
