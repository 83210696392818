import React, { useState } from "react";
import DataTable from "../common/DataTable";
import {
  ProductsApi,
  ApiLoaderComponent,
  LoadingComponent,
} from "@unity/components";
import { CanDelete } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";

export default function ProductMaterials(props) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await ProductsApi.deleteLabour(id);
  };

  const handleDeleteArray = async (array) => {
    setLoading({
      status: true,
      data: "Deleting product labour items, please wait...",
    });
    const unresolvedPromises = array.map((id) => handleDelete(id));

    await Promise.all(unresolvedPromises)
      .then((success) => {
        setLoading({
          status: true,
          data: "Succssfully deleted product labour items!",
        });
      })
      .catch((error) => {
        setLoading({
          status: true,
          data: `Unable to delete product labour item with id: ${error}`,
        });
      });

    // props.setCalcTrigger(true);

    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  const columns = [
    { field: "description", headerName: "Description", flex: 0.6 },
    {
      field: "periodic_code_id",
      headerName: "Periodic Code",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.periodic_code_id) {
          const pc = props.periods.find(
            (e) => cellValues.row.periodic_code_id === e.id
          );
          return pc.name;
        } else {
          return null;
        }
      },
    },
    { field: "quantity", headerName: "Quantity", flex: 0.3 },
    { field: "price", headerName: "Price", flex: 0.3 },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      renderCell: (cellValues) => {
        return (
          <div>
            {props.type === "edit" && !props.uneditable ? (
              <ModuleButton
                btnIcon="edit"
                style={{
                  background: "orange",
                  color: "white",
                  marginLeft: 10,
                }}
                onClick={() => {
                  props.setLabEdit(true);
                  props.setDialogData(cellValues.row);
                }}
                tip="Edit Labour Item"
              />
            ) : (
              <ModuleButton
                btnIcon="visibility"
                style={{
                  background: props.context.theme.sidebar.background,
                  color: "white",
                }}
                onClick={() => {
                  props.setLabEdit(true);
                  props.setDialogData(cellValues.row);
                }}
                tip="View Labour Item"
              />
            )}
          </div>
        );
      },
    },
  ];

  if (props.read && props.data.labour_costs) {
    return (
      <>
        <DataTable
          columns={columns}
          data={props.data.labour_costs}
          pagination={false}
          allowDelete={!props.uneditable && CanDelete("products")}
          handleDeleteArray={handleDeleteArray}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
