import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CookieRequestComponent } from "@unity/components";
import AuthContext from "./services/AuthContext";
import Products from "./pages/index";
import ProductView from "./pages/view";
import ProductEdit from "./pages/edit";
import ProductCreate from "./pages/create";

export default function Root(props) {
  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <CookieRequestComponent />

      <TransitionGroup className="transition-group">
        <CSSTransition timeout={300} classNames="fade">
          <section className="route-section">
            <Routes>

              {props.auth.access.products["A"] &&
                props.auth.access.products["R"] && (
                  <Route
                    path="/products/index"
                    element={<Products context={context} />}
                  />
                )}

                {props.auth.access.products["A"] &&
                  props.auth.access.products["R"] && (
                    <Route
                      path="/products/view/:id"
                      element={<ProductView context={context} />}
                    />
                  )}

                {props.auth.access.products["A"] &&
                  props.auth.access.products["C"] && (
                    <Route
                      path="/products/create"
                      element={<ProductCreate context={context} />}
                    />
                  )}

                {props.auth.access.products["A"] &&
                  props.auth.access.products["U"] && (
                    <Route
                      path="/products/edit/:id"
                      element={<ProductEdit context={context} />}
                    />
                  )}

                {props.auth.access.products["A"] ? (
                    <Route
                      path="*"
                      element={<Navigate to="/products/index" />}
                    />
                  ) : (
                    <Route
                      path="*"
                      element={<div><p>You do not have permission to view this page. Please contact your manager!</p></div>}
                    />
                  
                  )
                }

            </Routes>
          </section>
        </CSSTransition>
      </TransitionGroup>

    </AuthContext.Provider>
  );
}
