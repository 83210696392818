import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProductsApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin } from "../services/Permissions";
import ProductOverview from "../modules/ProductOverview";
import ModuleFunctions from "../modules/ModuleFunctions";

export default function ProductCreate(props) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState({ owner_id: props.context.auth.contact, owner_uuid: props.context.auth.id, owner_name: props.context.auth.name, dynamic: false });
  const [errors, setErrors] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [channels, setChannels] = useState(false);
  const [structure, setStructure] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [vats, setVats] = useState(false);

  const navigate = useNavigate();

  const create = CanCreate("products");
  const read = CanRead("products");
  const edit = CanUpdate("products");
  const admin = CanModuleAdmin("products");
  const readAll = CanReadAll("products");

  const productChange = (data) => {
      setData(prevState => ({
          ...prevState,
          ...data
      }));
  };

  const triggerChange = () => {
      setFetchTrigger(fetchTrigger + 1);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const structChange = (data) => {
      setStructure(data);
  };

  const channelChange = (data) => {
      setChannels(data);
  };

  const periodsChange = (data) => {
      setPeriods(data);
  };

  const vatsChange = (data) => {
      setVats(data);
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Creating your Product framework, Please Wait...." });

    const res = await ProductsApi.saveProduct(data);

    if(res.success) {
      setLoading({ status: true, data: "Successfully created Product framework" });
      setTimeout(() => {
        navigate(`/products/edit/${res.data.id}`, {
          state: { hasErrors: false }
        });
        setLoading({ status: false });
      }, 3000);
    } else {
        if(res.errors) {
            setErrors(res.errors);
            setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
            setTimeout(() => {
                setLoading({ status: false });
            }, 3000);
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                navigate("/prodsucts/index");
                setLoading({ status: false });
            }, 3000);
        }
    }
  };


  useEffect(() => {
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getStructure({ structChange: structChange });
    ModuleFunctions.getChannels({ channelChange: channelChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getVatRates({ vatsChange: vatsChange });
  }, [fetchTrigger]);

  
  if(create) {
    return (  
        <>
          <AppHeaderComponent
            saveBtn={handleSave}
            context={props.context}
            theme={props.context.theme}
            name="products"
            subpage="create"
            spacer={true}
          />

          <ProductOverview
            context={props.context}
            type='create'
            create={create}
            read={read}
            edit={edit}
            data={data}
            errors={errors}
            personnel={personnel}
            channels={channels}
            structure={structure}
            periods={periods}
            vats={vats}
            productChange={productChange}
          />
        
          <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />
        </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
