import {
  PersonnelApi,
  ProductsApi,
  MaterialsApi,
  DocumentVaultAPI,
  CodesApi,
  SuppliersApi,
  FinanceApi,
} from "@unity/components";
import Currency from "currency.js";

async function getPersonnel(props) {
  const res = await PersonnelApi.getPersonnel();

  if (res.success) {
    props.personnelChange(res.data);
  }
}

async function getBusinesses(props) {
  const res = await CodesApi.getBusinessUnits();
  if (res.success) {
    props.change(res.data);
  }
}

async function getStructure(props) {
  const res = await ProductsApi.getStructure();

  if (res.success) {
    props.structChange(res.data);
  }
}

async function getChannels(props) {
  const res = await CodesApi.getSalesChannels();

  if (res.success) {
    props.channelChange(res.data);
  }
}

async function getSchedules(props) {
  const res = await ProductsApi.getSchedules();

  if (res.success) {
    props.scheduleChange(res.data);
  }
}

async function getDocuments(props) {
  const res = await ProductsApi.getDocuments();

  if (res.success) {
    props.docsChange(res.data);
  }
}

async function getTags(props) {
  const res = await ProductsApi.getTags();

  if (res.success) {
    props.tagsChange(res.data);
  }
}

async function getMaterialCats(props) {
  const res = await MaterialsApi.getCategories();

  if (res.success) {
    props.tabsChange(res.data);
  }
}

async function getCatMaterials(props) {
  const res = await MaterialsApi.getCategoryMaterials(props.category);

  if (res.success) {
    props.materialsChange(res.data);
  }
}

async function getPeriodicCodes(props) {
  const res = await CodesApi.getPeriodicCodes();

  if (res.success) {
    props.periodsChange(res.data);
  }
}

async function getChargeCodes(props) {
  const res = await CodesApi.getChargeCodes();

  if (res.success) {
    props.chargesChange(res.data);
  }
}

async function getPaymentMethods(props) {
  const res = await CodesApi.getPaymentMethods();

  if (res.success) {
    props.methodsChange(res.data);
  }
}

async function getVatRates(props) {
  const res = await CodesApi.getVatRates();

  if (res.success) {
    props.vatsChange(res.data);
  }
}

async function getPositions(props) {
  const res = await FinanceApi.getPositions();

  if (res.success) {
    props.positionsChange(res.data);
  }
}

async function getVisibilities(props) {
  const res = await ProductsApi.getVisibilities();

  if (res.success) {
    props.visiChange(res.data);
  }
}

async function getProduct(props) {
  const res = await ProductsApi.getProduct(props.id);

  if (res.success) {
    props.productChange(res.data);
  }
}

async function getSupplierName(props) {
  const res = await SuppliersApi.getSupplierSingle(props.id);

  if (res.success) {
    props.componentChange({
      supplier_name: res.data.name,
    });
  }
}

function getCodeName(props) {
  if (Array.isArray(props.codesArray)) {
    const obj = props.codesArray.find((element) => element.id === props.id);

    if (typeof obj !== "undefined") {
      return obj.name;
    }
  }
  return "undefined";
}

async function handleView(props) {
  const res = await DocumentVaultAPI.generateTempLink(props.id);
  window.open(res.data, "_blank");
}

async function handleAssign(props) {
  const newData = {};
  newData[props.event.target.name] = props.event.target.value;

  if (props.event.target.name === "sbu_id") {
    getProductCats({ change: props.catsChange, id: props.event.target.value });
    newData["sbu_name"] = props.element.props.children;
  } else {
    newData["prod_cat_name"] = props.element.props.children;
  }

  props.docChange(newData);
}

function handleDocChange(props) {
  const newData = {};
  newData[props.name] = props.value;

  props.docChange(newData);
}

function handleModelChange(props) {
  const newData = {};
  newData[props.name] = props.value;
  newData["changed"] = true;

  props.modelChange(newData);
}

function handleProductChange(props) {
  const newData = {};
  newData[props.name] = props.value;
  newData["changed"] = true;

  props.productChange(newData);
}

function handleComponentChange(props) {
  const newData = {};
  newData[props.name] = props.value;

  props.componentChange(newData);
}

function handleComponentCalcChange(props) {
  const GBP = (value) =>
    Currency(value, { symbol: "£", decimal: ".", separator: "," });
  const newData = { ...props.data };

  const value =
    props.value.indexOf(".") >= 0
      ? props.value.substr(0, props.value.indexOf(".")) +
        props.value.substr(props.value.indexOf("."), 3)
      : props.value;

  newData[props.name] = value;

  const cost = GBP(newData["material_cost"]);
  const total = GBP(newData["price"]);

  if (props.name !== "price" && newData.material_cost && newData.quantity) {
    const markup = GBP(cost).multiply(
      (parseFloat(newData["markup"]) || 0) / 100
    );
    const sub = GBP(cost).add(markup);
    const work = sub.multiply(newData["quantity"]);
    newData["unit_price"] = sub.value;
    newData["price"] = work.value;
  } else if (
    props.name === "price" &&
    newData.material_cost &&
    newData.quantity
  ) {
    const subTot = GBP(cost).multiply(newData["quantity"]);
    const over = GBP(total).subtract(subTot);
    const markup = over.divide(cost).multiply(100);
    const unit = GBP(cost).divide(newData["quantity"]);
    newData["unit_price"] = unit.value;
    newData["markup"] = markup.value;
  } else {
    newData["unit_price"] = null;
    newData["price"] = null;
  }

  props.componentChange(newData);
}

function handleLabourChange(props) {
  const newData = {};
  newData[props.name] = props.value;

  props.labourChange(newData);
}

function handleLabourCalcChange(props) {
  const GBP = (value) =>
    Currency(value, { symbol: "£", decimal: ".", separator: "," });
  const newData = { ...props.data };

  const value =
    props.value.indexOf(".") >= 0
      ? props.value.substr(0, props.value.indexOf(".")) +
        props.value.substr(props.value.indexOf("."), 3)
      : props.value;

  newData[props.name] = value;

  const cost = GBP(newData["rate"]);
  const total = GBP(newData["price"]);

  if (props.name !== "price" && newData.rate && newData.quantity) {
    const markup = GBP(cost).multiply(
      (parseFloat(newData["markup"]) || 0) / 100
    );
    const sub = GBP(cost).add(markup);
    const work = sub.multiply(newData["quantity"]);
    newData["unit_price"] = sub.value;
    newData["price"] = work.value;
  } else if (props.name === "price" && newData.rate && newData.quantity) {
    const subTot = GBP(cost).multiply(newData["quantity"]);
    const over = GBP(total).subtract(subTot);
    const markup = over.divide(cost).multiply(100);
    const unit = GBP(cost).divide(newData["quantity"]);
    newData["unit_price"] = unit.value;
    newData["markup"] = markup.value;
  } else {
    newData["unit_price"] = null;
    newData["price"] = null;
  }

  props.labourChange(newData);
}

function handleOwnerChange(props) {
  props.productChange({
    changed: true,
    owner_id: props.event.target.value,
    owner_uuid: props.element.props.personObj.uuid,
    owner_name: props.element.props.personObj.name,
  });
}

function testReason(props) {
  let issues = 0;
  const newErrs = {};

  if (!props.reason) {
    newErrs["reason"] = "You must give a approval rejection reason!";
    issues += 1;
  }

  return issues > 0 ? newErrs : false;
}

/**
 * This function will delete a schedule reference.
 *
 * @param   {*}         props       has the elements:
 * @param   {number}    id          the current section id
 * @function    triggerChange       to update the document state
 */
async function handleDeleteReference(props) {
  const res = await ProductsApi.deleteSchedule(props.id);

  if (res.success) {
    props.triggerChange();
  }
}

/**
 * This function will unlink a document from the specified object.
 *
 * @param   {*}         props       has the elements:
 * @param   {number}    id          the current document id
 * @function    triggerChange       to update the document state
 */
async function handleUnlink(props) {
  const oldLinks = {
    object_id: props.object_id,
    object_type: props.object_type,
    details: [props.id], // if you change so multiple items can be linked then just add them to this array
  };

  const res = await ProductsApi.removeDocuments(oldLinks);

  if (res.success) {
    props.triggerChange();
  }
}

/**
 * This function will set a document as the Primary document for the specified Product.
 *
 * @param   {*}         props       has the elements:
 * @param   {number}    id          the current document id
 * @function    triggerChange       to update the document state
 */
async function handlePrimary(props) {
  const obj = {
    type: props.object_type,
    type_id: Number.parseInt(props.object_id),
  };

  const res = await ProductsApi.updatePrimary(props.id, obj);

  if (res.success) {
    props.triggerChange();
  }
}

/**
 * This function will set the prodCats state in the component and update the product object.
 *
 * @param   {*}         props       has the elements:
 * @param   {integer}   current     the current sbu_id
 * @param   {integer}   selected    the selected sbu_id
 * @param   {object}    element     the selected element
 * @function    productChange       to update the product state
 * @function    prodCatsChange      to update the prodCats state
 */
async function handleSbuChange(props) {
  const newData = {};

  newData["sbu_id"] = props.selected;
  newData["changed"] = true;

  if (props.selected !== props.current) {
    (newData["prod_cat_id"] = null),
      (newData["group_id"] = null),
      (newData["line_id"] = null);
  }

  props.prodCatsChange(
    props.element.props.structObj[props.element.props.structObj.children_array]
  );
  props.productChange(newData);
}

/**
 * This function will set the groups state in the component and update the product object.
 *
 * @param   {*}         props       has the elements:
 * @param   {integer}   current     the current prod_cat_id
 * @param   {integer}   selected    the selected prod_cat_id
 * @param   {object}    element     the selected element
 * @function    productChange       to update the product state
 * @function    groupsChange        to update the groups state
 */
async function handleProdCatChange(props) {
  const newData = {};

  newData["prod_cat_id"] = props.selected;
  newData["changed"] = true;

  if (props.selected !== props.current) {
    (newData["group_id"] = null), (newData["line_id"] = null);
  }

  props.groupsChange(
    props.element.props.structObj[props.element.props.structObj.children_array]
  );
  props.productChange(newData);
}

/**
 * This function will set the lines state in the component and update the product object.
 *
 * @param   {*}         props       has the elements:
 * @param   {integer}   current     the current group_id
 * @param   {integer}   selected    the selected group_id
 * @param   {object}    element     the selected element
 * @function    productChange       to update the product state
 * @function    linesChange         to update the lines state
 */
async function handleProdGroupChange(props) {
  const newData = {};

  newData["group_id"] = props.selected;
  newData["changed"] = true;

  if (props.selected !== props.current) {
    newData["line_id"] = null;
  }

  props.linesChange(
    props.element.props.structObj[props.element.props.structObj.children_array]
  );
  props.productChange(newData);
}

/**
 * This function will set the structure states in the product overview.
 *
 * @param   {*}         props       has the elements:
 * @param   {object}    data        the current product
 * @param   {object}    structure   the product structure
 * @function    prodCatsChange      to update the product categories state
 * @function    groupsChange         to update the product groups state
 * @function    linesChange         to update the product lines state
 */
function setCurrentProductStructure(props) {
  const cats = props.structure.find(
    (element) => element.id === props.data.sbu_id
  );
  props.prodCatsChange(cats[cats.children_array]);

  if (props.data.prod_cat_id) {
    const groups = cats[cats.children_array].find(
      (element) => element.id === props.data.prod_cat_id
    );
    props.groupsChange(groups[groups.children_array]);

    if (props.data.group_id) {
      const lines = groups[groups.children_array].find(
        (element) => element.id === props.data.group_id
      );
      props.linesChange(lines[lines.children_array]);
    }
  }
}

/**
 * This function will set the dialogData state for the component being created/editted.
 *
 * @param   {*}         props       has the elements:
 * @param   {object}    event       the click event
 * @param   {object}    element     the selected element
 * @param   {boolean}   asset       flag to notify if the object is an asset
 * @function    componentChange     to update the dialogData state
 * @function    supplierChange      to update the supplier state
 */
async function handleMaterialChange(props) {
  const newData = {};

  newData["material_id"] = props.event.target.value;
  newData["material_name"] = props.element.props.matObj.brand
    ? props.element.props.matObj.brand + " " + props.element.props.matObj.name
    : props.element.props.matObj.name;
  newData["material_ref"] = props.element.props.matObj.reference;
  newData["supplier_id"] = props.element.props.matObj.supplier_id;
  newData["material_cost"] = props.element.props.matObj.unit_cost;
  newData["periodic_code_id"] = props.element.props.matObj.periodic_code_id;

  if (props.asset) {
    newData["writeoff_pc_id"] = props.element.props.matObj.write_off_id;
    newData["writeoff_length"] = props.element.props.matObj.writeoff_length;
  }

  props.componentChange(newData);
  props.triggerChange(); // this will cause useEffect to get the supplier and update the form field
}

/**
 * This function will set the dialogData state for the labour item being created/editted.
 *
 * @param   {*}         props       has the elements:
 * @param   {object}    event       the click event
 * @param   {object}    element     the selected element
 * @function    labourChange     to update the dialogData state
 */
async function handlePositionChange(props) {
  const newData = {};

  newData["position_id"] = props.event.target.value;
  newData["position_name"] = props.element.props.labObj.name;
  newData["rate"] = props.element.props.labObj.rate;

  if (props.element.props.labObj.periodic_code_id) {
    newData["periodic_code_id"] = props.element.props.labObj.periodic_code_id;
  }

  props.labourChange(newData);
}

export default {
  getPersonnel,
  getBusinesses,
  getStructure,
  getChannels,
  getSchedules,
  getDocuments,
  getTags,
  getMaterialCats,
  getCatMaterials,
  getPeriodicCodes,
  getChargeCodes,
  getPaymentMethods,
  getVatRates,
  getPositions,
  getVisibilities,
  getProduct,
  getSupplierName,
  getCodeName,
  handleView,
  handleAssign,
  handleDocChange,
  handleModelChange,
  handleProductChange,
  handleComponentChange,
  handleComponentCalcChange,
  handleLabourChange,
  handleLabourCalcChange,
  handleOwnerChange,
  testReason,
  handleDeleteReference,
  handleUnlink,
  handlePrimary,
  handleSbuChange,
  handleProdCatChange,
  handleProdGroupChange,
  setCurrentProductStructure,
  handleMaterialChange,
  handlePositionChange,
};
